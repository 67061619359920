import React, { useMemo } from "react";
import { Container, Typography, Box, Button, Grid2, Tooltip } from "@mui/material";

import { MaterialReactTable } from "material-react-table";
import { MRT_Localization_JA } from "material-react-table/locales/ja";

import { useNavigate } from "react-router-dom";

import { Helmet } from "react-helmet-async";

const Dispatch = ({ val }) => {
    const navigate = useNavigate();

    const dispatchData = [
        {
            companyName: "HRマネージドサービス株式会社",
            address: "東京都新宿区西新宿三丁目3番13号 西新宿水間ビル6F",
            officeCount: 1,
            dispatchCount: 0,
        },
        {
            companyName:
                "株式会社めっちゃ名前長い企業がちゃんと三点リーダーで表示されるかテストしています。マウスオーバーすると、すべて表示されます。",
            address: "東京都新宿区西新宿三丁目3番13号 ビル名が異常に長いとどのように表示されるかテストしています。",
            officeCount: 1,
            dispatchCount: 99999,
        },
        { companyName: "企業C", address: "住所C", officeCount: 2, dispatchCount: 99999 },
        { companyName: "企業D", address: "住所D", officeCount: 3, dispatchCount: 88888 },
        { companyName: "企業E", address: "住所E", officeCount: 4, dispatchCount: 77777 },
        { companyName: "企業F", address: "住所F", officeCount: 5, dispatchCount: 66666 },
        { companyName: "企業G", address: "住所G", officeCount: 6, dispatchCount: 55555 },
        { companyName: "企業H", address: "住所H", officeCount: 7, dispatchCount: 44444 },
        { companyName: "企業I", address: "住所I", officeCount: 8, dispatchCount: 33333 },
        { companyName: "企業J", address: "住所J", officeCount: 9, dispatchCount: 22222 },
        { companyName: "企業K", address: "住所K", officeCount: 10, dispatchCount: 11111 },
        { companyName: "企業L", address: "住所L", officeCount: 11, dispatchCount: 10000 },
        { companyName: "企業M", address: "住所M", officeCount: 12, dispatchCount: 9000 },
        { companyName: "企業N", address: "住所N", officeCount: 13, dispatchCount: 8000 },
        { companyName: "企業O", address: "住所O", officeCount: 14, dispatchCount: 7000 },
        { companyName: "企業P", address: "住所P", officeCount: 15, dispatchCount: 6000 },
        { companyName: "企業Q", address: "住所Q", officeCount: 16, dispatchCount: 5000 },
        { companyName: "企業R", address: "住所R", officeCount: 17, dispatchCount: 4000 },
        { companyName: "企業S", address: "住所S", officeCount: 18, dispatchCount: 3000 },
        { companyName: "企業T", address: "住所T", officeCount: 19, dispatchCount: 2000 },
    ];

    const columns = useMemo(
        () => [
            {
                header: "派遣先企業名",
                accessorKey: "companyName",
                Cell: ({ cell }) => (
                    <Tooltip title={cell.getValue()} placement="top" arrow>
                        <Typography
                            variant="body2"
                            sx={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                            }}
                        >
                            {cell.getValue()}
                        </Typography>
                    </Tooltip>
                ),
                muiTableBodyCellProps: { sx: { maxWidth: "500px", minWidth: "500px" } },
            },
            {
                header: "住所",
                accessorKey: "address",
                Cell: ({ cell }) => (
                    <Tooltip title={cell.getValue()} placement="top" arrow>
                        <Typography
                            variant="body2"
                            sx={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                            }}
                        >
                            {cell.getValue()}
                        </Typography>
                    </Tooltip>
                ),
                muiTableBodyCellProps: { sx: { maxWidth: "500px", minWidth: "500px" } },
            },
            {
                header: "事業所数",
                accessorKey: "officeCount",
                muiTableBodyCellProps: { sx: { maxWidth: "100px", minWidth: "100px" } },
                Cell: ({ cell }) => cell.getValue().toLocaleString(),
            },
            {
                header: "派遣人数",
                accessorKey: "dispatchCount",
                muiTableBodyCellProps: { sx: { maxWidth: "100px", minWidth: "100px" } },
                Cell: ({ cell }) => cell.getValue().toLocaleString(),
            },
        ],
        []
    );

    return (
        <>
            <Helmet>
                <title>派遣先企業一覧 - HR System (仮)</title>
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>

            <Box sx={{ pl: val === true ? 6 : 30 }}>
                <Container maxWidth="false" sx={{ mt: 10 }}>
                    <Grid2 container>
                        <Grid2
                            size={{ xs: 8, md: 8 }}
                            sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}
                        >
                            <Typography variant="h6">派遣先企業一覧</Typography>
                        </Grid2>
                        <Grid2
                            size={{ xs: 4, md: 4 }}
                            sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}
                        >
                            <Button variant="outlined" size="large" color="primary">
                                派遣先企業を追加する
                            </Button>
                        </Grid2>
                    </Grid2>

                    <Box sx={{ mt: 2 }}>
                        <MaterialReactTable
                            columns={columns}
                            data={dispatchData}
                            enableColumnActions={false}
                            enableColumnResizing={false}
                            enableDensityToggle={false}
                            enableFullScreenToggle={false}
                            enableHiding={false}
                            localization={MRT_Localization_JA}
                        />
                    </Box>
                </Container>
            </Box>
        </>
    );
};

export default Dispatch;
