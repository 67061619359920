import React, { useMemo, useState } from "react";
import {
    Container,
    Typography,
    Box,
    Button,
    Grid2,
    Tooltip,
    Divider,
    IconButton,
    Tabs,
    Tab,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    TextField,
    Alert,
} from "@mui/material";

import SettingsIcon from "@mui/icons-material/Settings";
import CloseIcon from "@mui/icons-material/Close";

import { MaterialReactTable } from "material-react-table";
import { MRT_Localization_JA } from "material-react-table/locales/ja";

import { useNavigate } from "react-router-dom";

import { Helmet } from "react-helmet-async";

const Company = ({ val }) => {
    const navigate = useNavigate();

    const [companyData, setCompanyData] = useState({
        companyName: { title: "企業名", value: "HRマネージドサービス株式会社" },
        companyRepresentative: { title: "代表者の役職と氏名", value1: "代表取締役", value2: "絢瀬 絵里" },
        companyAddress: {
            title: "住所",
            post: "1000000",
            prefecture: "東京都",
            city: "千代田区",
            other: "千代田1-1-1",
            building: "皇居",
        },
        dispatchBusiness: { title: "労働者派遣事業", value1: 1, value2: "13", value3: "123456" },
        dispatchAmount: { title: "労働者派遣に関する料金の平均額", value: "35000" },
        laborAgreement: { title: "労使協定", value: true, startDate: "2024/01/01", endDate: "2024/12/31" },
        payment: {
            title: "支払条件",
            closing: "締め日",
            closingValue: "末日",
            date: "支払日",
            dateValue: "翌月20日",
            method: "支払方法",
            methodValue: "銀行振込",
        },
    });

    const userData = [
        {
            name: "高坂 穂乃果",
            phone: "090-1234-5678",
            email: "h-kousaka@hr-ms.jp",
            division: "営業部",
            charge: "派遣元責任者, 苦情申し出先",
            authority: "管理者",
        },
        {
            name: "南 ことり",
            phone: "090-2345-6789",
            email: "k-minami@hr-ms.jp",
            division: "営業部 刈谷営業所",
            charge: "派遣元責任者",
            authority: "閲覧のみ",
        },
        {
            name: "園田 海未",
            phone: "090-3456-7890",
            email: "u-sonoda@hr-ms.jp",
            division: "営業部 名古屋営業所",
            charge: "苦情申し出先",
            authority: "管理者",
        },
        {
            name: "星空 凛",
            phone: "090-4567-8901",
            email: "r-hoshizora@hr-ms.jp",
            division: "営業部 大阪営業所",
            charge: "なし",
            authority: "担当者",
        },
        {
            name: "西木野 真姫",
            phone: "090-5678-9012",
            email: "m-nishikino@hr-ms.jp",
            division: "営業部 福岡営業所",
            charge: "派遣元責任者, 苦情申し出先",
            authority: "管理者",
        },
        {
            name: "小泉 花陽",
            phone: "090-7890-1234",
            email: "h-koizumi@hr-ms.jp",
            division: "営業部 沖縄営業所",
            charge: "苦情申し出先",
            authority: "閲覧のみ",
        },
        {
            name: "東條 希",
            phone: "090-6789-0123",
            email: "n-tojo@hr-ms.jp",
            division: "営業部",
            charge: "派遣元責任者",
            authority: "担当者",
        },
        {
            name: "矢澤 にこ",
            phone: "090-8901-2345",
            email: "n-yazawa@hr-ms.jp",
            division: "営業部",
            charge: "なし",
            authority: "閲覧のみ",
        },
        {
            name: "絢瀬 絵里",
            phone: "090-9012-3456",
            email: "e-ayase@hr-ms.jp",
            division: "営業部",
            charge: "派遣元責任者, 苦情申し出先",
            authority: "管理者",
        },
    ];

    const [authorityData, setAuthorityData] = useState(["管理者", "担当者", "閲覧のみ"]);

    const [chargeData, setChargeData] = useState([
        "派遣元責任者, 苦情申し出先",
        "派遣元責任者",
        "苦情申し出先",
        "なし",
    ]);

    const [divisionData, setDivisionData] = useState([
        "営業部",
        "営業部 刈谷営業所",
        "営業部 名古屋営業所",
        "営業部 大阪営業所",
        "営業部 福岡営業所",
        "営業部 沖縄営業所",
    ]);

    const [employeeData, setEmployeeData] = useState(["一般", "エキスパート社員"]);

    const [positionData, setPositionData] = useState(["一般", "エキスパート社員"]);

    const columns = useMemo(
        () => [
            {
                header: "ユーザー名",
                accessorKey: "name",
                Cell: ({ cell }) => (
                    <Tooltip title={cell.getValue()} placement="top" arrow>
                        <Typography
                            variant="body2"
                            sx={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                            }}
                        >
                            {cell.getValue()}
                        </Typography>
                    </Tooltip>
                ),
                muiTableBodyCellProps: { sx: { maxWidth: "300px", minWidth: "300px" } },
            },
            {
                header: "電話番号",
                accessorKey: "phone",
                muiTableBodyCellProps: { sx: { maxWidth: "100px", minWidth: "100px" } },
            },
            {
                header: "メールアドレス",
                accessorKey: "email",
                Cell: ({ cell }) => (
                    <Tooltip title={cell.getValue()} placement="top" arrow>
                        <Typography
                            variant="body2"
                            sx={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                            }}
                        >
                            {cell.getValue()}
                        </Typography>
                    </Tooltip>
                ),
                muiTableBodyCellProps: { sx: { maxWidth: "300px" } },
            },
            {
                header: "部署",
                accessorKey: "division",
                Cell: ({ cell }) => (
                    <Tooltip title={cell.getValue()} placement="top" arrow>
                        <Typography
                            variant="body2"
                            sx={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                            }}
                        >
                            {cell.getValue()}
                        </Typography>
                    </Tooltip>
                ),
                muiTableBodyCellProps: { sx: { maxWidth: "300px", minWidth: "300px" } },
                filterVariant: "select",
                filterSelectOptions: divisionData,
            },
            {
                header: "担当業務",
                accessorKey: "charge",
                muiTableBodyCellProps: { sx: { maxWidth: "200px", minWidth: "200px" } },
                filterVariant: "select",
                filterSelectOptions: chargeData,
            },
            {
                header: "権限",
                accessorKey: "authority",
                muiTableBodyCellProps: { sx: { maxWidth: "100px", minWidth: "100px" } },
                filterVariant: "select",
                filterSelectOptions: authorityData,
            },
        ],
        []
    );

    const [addDivisionData, setAddDivisionData] = useState("");
    const [addEmployeeData, setAddEmployeeData] = useState("");
    const [addPositionData, setAddPositionData] = useState("");

    const [editDivisionData, setEditDivisionData] = useState("営業部");
    const [editEmployeeData, setEditEmployeeData] = useState("一般");
    const [editPositionData, setEditPositionData] = useState("エキスパート社員");

    const [addDivisionError, setAddDivisionError] = useState("");
    const [addEmployeeError, setAddEmployeeError] = useState("");
    const [addPositionError, setAddPositionError] = useState("");

    const [editDivisionError, setEditDivisionError] = useState("");
    const [editEmployeeError, setEditEmployeeError] = useState("");
    const [editPositionError, setEditPositionError] = useState("");

    const handleCompanyEdit = () => {
        window.scrollTo(0, 0);
        navigate("/company/edit");
    };

    const handleUserAdd = () => {
        window.scrollTo(0, 0);
        navigate("/user-add");
    };

    const handleUserDetail = (id) => {
        window.scrollTo(0, 0);
        // navigate(`/user/${id}`);
        navigate("/user/detail");
    };

    const [open, setOpen] = useState(false);
    const [tabIndex, setTabIndex] = useState(0);

    const [addDivisionOpen, setAddDivisionOpen] = useState(false);
    const [addEmployeeOpen, setAddEmployeeOpen] = useState(false);
    const [addPositionOpen, setAddPositionOpen] = useState(false);

    const [addDivisionCompleteOpen, setAddDivisionCompleteOpen] = useState(false);
    const [addEmployeeCompleteOpen, setAddEmployeeCompleteOpen] = useState(false);
    const [addPositionCompleteOpen, setAddPositionCompleteOpen] = useState(false);

    const [editDivisionOpen, setEditDivisionOpen] = useState(false);
    const [editEmployeeOpen, setEditEmployeeOpen] = useState(false);
    const [editPositionOpen, setEditPositionOpen] = useState(false);

    const [editDivisionCompleteOpen, setEditDivisionCompleteOpen] = useState(false);
    const [editEmployeeCompleteOpen, setEditEmployeeCompleteOpen] = useState(false);
    const [editPositionCompleteOpen, setEditPositionCompleteOpen] = useState(false);

    const [deleteDivisionOpen, setDeleteDivisionOpen] = useState(false);
    const [deleteEmployeeOpen, setDeleteEmployeeOpen] = useState(false);
    const [deletePositionOpen, setDeletePositionOpen] = useState(false);

    const [deleteDivisionCompleteOpen, setDeleteDivisionCompleteOpen] = useState(false);
    const [deleteEmployeeCompleteOpen, setDeleteEmployeeCompleteOpen] = useState(false);
    const [deletePositionCompleteOpen, setDeletePositionCompleteOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
    };

    const handleDivisionAdd = () => {
        setOpen(false);
        setAddDivisionOpen(true);
    };

    const handleAddDivisionChange = (e) => {
        const value = e.target.value.replace(/　/g, " ").trim();
        setAddDivisionData(value);
        setAddDivisionError(value === "" ? "所属部署を入力してください" : "");
    };

    const handleAddDivisionBlur = () => {
        setAddDivisionError(addDivisionData === "" ? "所属部署を入力してください" : "");
    };

    const handleAddDivisionClose = () => {
        setAddDivisionOpen(false);
        setAddDivisionCompleteOpen(false);
        setOpen(true);
    };

    const handleDivisionRegister = () => {
        if (addDivisionData === "") {
            setAddDivisionError("所属部署を入力してください");
            return;
        } else {
            setAddDivisionError("");
            setAddDivisionOpen(false);
            setAddDivisionCompleteOpen(true);
        }
    };

    const handleDivisionEdit = () => {
        setOpen(false);
        setEditDivisionOpen(true);
    };

    const handleEditDivisionChange = (e) => {
        setEditDivisionData(e.target.value);
        setEditDivisionError(e.target.value === "" ? "所属部署を入力してください" : "");
    };
    const handleEditDivisionBlur = () => {
        setEditDivisionError(editDivisionData === "" ? "所属部署を入力してください" : "");
    };

    const handleDivisionUpdate = () => {
        if (editDivisionData === "") {
            setEditDivisionError("所属部署を入力してください");
            return;
        } else {
            setEditDivisionError("");
            setEditDivisionData("営業部");
            setEditDivisionOpen(false);
            setEditDivisionCompleteOpen(true);
        }
    };

    const handleEditDivisionClose = () => {
        setEditDivisionOpen(false);
        setEditDivisionCompleteOpen(false);
        setOpen(true);
    };

    const handleDivisionDelete = () => {
        setEditDivisionOpen(false);
        setDeleteDivisionOpen(true);
    };

    const handleDeleteDivisionClose = () => {
        setDeleteDivisionOpen(false);
        setEditDivisionOpen(true);
    };

    const handleDivisionDeleteExecute = () => {
        setDeleteDivisionOpen(false);
        setDeleteDivisionCompleteOpen(true);
    };

    const handleDeleteDivisionCompleteClose = () => {
        setDeleteDivisionCompleteOpen(false);
        setOpen(true);
    };

    const handleEmployeeAdd = () => {
        setOpen(false);
        setAddEmployeeOpen(true);
    };

    const handleAddEmployeeChange = (e) => {
        const value = e.target.value.replace(/　/g, " ").trim();
        setAddEmployeeData(value);
        setAddEmployeeError(value === "" ? "社員区分を入力してください" : "");
    };

    const handleAddEmployeeBlur = () => {
        setAddEmployeeError(addEmployeeData === "" ? "社員区分を入力してください" : "");
    };

    const handleAddEmployeeClose = () => {
        setAddEmployeeOpen(false);
        setAddEmployeeCompleteOpen(false);
        setOpen(true);
    };

    const handleEmployeeRegister = () => {
        if (addEmployeeData === "") {
            setAddEmployeeError("社員区分を入力してください");
            return;
        } else {
            setAddEmployeeError("");
            setAddEmployeeOpen(false);
            setAddEmployeeCompleteOpen(true);
        }
    };

    const handleEmployeeEdit = () => {
        setOpen(false);
        setEditEmployeeOpen(true);
    };

    const handleEditEmployeeChange = (e) => {
        setEditEmployeeData(e.target.value);
        setEditEmployeeError(e.target.value === "" ? "社員区分を入力してください" : "");
    };
    const handleEditEmployeeBlur = () => {
        setEditEmployeeError(editEmployeeData === "" ? "社員区分を入力してください" : "");
    };

    const handleEmployeeUpdate = () => {
        if (editEmployeeData === "") {
            setEditEmployeeError("社員区分を入力してください");
            return;
        } else {
            setEditEmployeeError("");
            setEditEmployeeData("一般");
            setEditEmployeeOpen(false);
            setEditEmployeeCompleteOpen(true);
        }
    };

    const handleEditEmployeeClose = () => {
        setEditEmployeeOpen(false);
        setEditEmployeeCompleteOpen(false);
        setOpen(true);
    };

    const handleEmployeeDelete = () => {
        setEditEmployeeOpen(false);
        setDeleteEmployeeOpen(true);
    };

    const handleDeleteEmployeeClose = () => {
        setDeleteEmployeeOpen(false);
        setEditEmployeeOpen(true);
    };

    const handleEmployeeDeleteExecute = () => {
        setDeleteEmployeeOpen(false);
        setDeleteEmployeeCompleteOpen(true);
    };

    const handleDeleteEmployeeCompleteClose = () => {
        setDeleteEmployeeCompleteOpen(false);
        setOpen(true);
    };

    const handlePositionAdd = () => {
        setOpen(false);
        setAddPositionOpen(true);
    };

    const handleAddPositionChange = (e) => {
        const value = e.target.value.replace(/　/g, " ").trim();
        setAddPositionData(value);
        setAddPositionError(value === "" ? "職制区分を入力してください" : "");
    };

    const handleAddPositionBlur = () => {
        setAddPositionError(addPositionData === "" ? "職制区分を入力してください" : "");
    };

    const handleAddPositionClose = () => {
        setAddPositionOpen(false);
        setAddPositionCompleteOpen(false);
        setOpen(true);
    };

    const handlePositionRegister = () => {
        if (addPositionData === "") {
            setAddPositionError("職制区分を入力してください");
            return;
        } else {
            setAddPositionError("");
            setAddPositionOpen(false);
            setAddPositionCompleteOpen(true);
        }
    };

    const handlePositionEdit = () => {
        setOpen(false);
        setEditPositionOpen(true);
    };

    const handleEditPositionChange = (e) => {
        setEditPositionData(e.target.value);
        setEditPositionError(e.target.value === "" ? "職制区分を入力してください" : "");
    };
    const handleEditPositionBlur = () => {
        setEditPositionError(editPositionData === "" ? "職制区分を入力してください" : "");
    };

    const handlePositionUpdate = () => {
        if (editPositionData === "") {
            setEditPositionError("職制区分を入力してください");
            return;
        } else {
            setEditPositionError("");
            setEditPositionData("エキスパート社員");
            setEditPositionOpen(false);
            setEditPositionCompleteOpen(true);
        }
    };

    const handleEditPositionClose = () => {
        setEditPositionOpen(false);
        setEditPositionCompleteOpen(false);
        setOpen(true);
    };

    const handlePositionDelete = () => {
        setEditPositionOpen(false);
        setDeletePositionOpen(true);
    };

    const handleDeletePositionClose = () => {
        setDeletePositionOpen(false);
        setEditPositionOpen(true);
    };

    const handlePositionDeleteExecute = () => {
        setDeletePositionOpen(false);
        setDeletePositionCompleteOpen(true);
    };

    const handleDeletePositionCompleteClose = () => {
        setDeletePositionCompleteOpen(false);
        setOpen(true);
    };

    return (
        <>
            <Helmet>
                <title>会社情報 - HR System (仮)</title>
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>

            <Box sx={{ pl: val === true ? 6 : 30 }}>
                <Container maxWidth="false" sx={{ mt: 10 }}>
                    <Grid2 container sx={{ mb: 3 }}>
                        <Grid2
                            size={{ xs: 8, md: 8 }}
                            sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}
                        >
                            <Typography variant="h6">会社情報</Typography>
                        </Grid2>
                        <Grid2
                            size={{ xs: 4, md: 4 }}
                            sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}
                        >
                            <Button variant="outlined" size="large" color="primary" onClick={handleCompanyEdit}>
                                会社情報を編集する
                            </Button>
                            <IconButton sx={{ ml: 2 }} onClick={handleOpen}>
                                <SettingsIcon />
                            </IconButton>
                        </Grid2>
                    </Grid2>

                    {Object.entries(companyData).map(([key, data]) => (
                        <Box key={key}>
                            <Grid2 container spacing={2}>
                                <Grid2
                                    size={{ xs: 5, md: 5 }}
                                    sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}
                                >
                                    <Typography fontWeight="bold">{data.title}</Typography>
                                </Grid2>
                                <Grid2
                                    size={{ xs: 7, md: 7 }}
                                    sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}
                                >
                                    {key === "companyRepresentative" ? (
                                        <>
                                            <Typography>{data.value1}</Typography>
                                            <Typography sx={{ ml: 2 }}>{data.value2}</Typography>
                                        </>
                                    ) : key === "companyAddress" ? (
                                        <>
                                            <Typography sx={{ textAlign: "left" }}>
                                                {"〒" + data.post.replace(/(\d{3})(?=\d)/, "$1-")}
                                                <br />
                                                {data.prefecture}
                                                {data.city}
                                                {data.other} {data.building}
                                            </Typography>
                                        </>
                                    ) : key === "dispatchBusiness" ? (
                                        <>
                                            <Typography>
                                                {data.value1 === 1 ? "派" : data.value1 === 2 ? "地" : data.value1}
                                                {data.value2}-{data.value3}
                                            </Typography>
                                        </>
                                    ) : key === "dispatchAmount" ? (
                                        <Typography>
                                            {data.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}円
                                        </Typography>
                                    ) : key === "laborAgreement" ? (
                                        <Typography sx={{ textAlign: "left" }}>
                                            {data.value ? "締結済み" : "なし"}
                                            {data.value === true && (
                                                <>
                                                    <br />
                                                    {data.startDate} 〜 {data.endDate}
                                                </>
                                            )}
                                        </Typography>
                                    ) : key === "payment" ? (
                                        <Typography sx={{ textAlign: "left" }}>
                                            {data.closing}　　　{data.closingValue}
                                            <br />
                                            {data.date}　　　{data.dateValue}
                                            <br />
                                            {data.method}　　{data.methodValue}
                                        </Typography>
                                    ) : (
                                        <Typography>{data.value}</Typography>
                                    )}
                                </Grid2>
                            </Grid2>
                            <Divider sx={{ my: 1 }} />
                        </Box>
                    ))}

                    <Grid2 container sx={{ mt: 5 }}>
                        <Grid2
                            size={{ xs: 8, md: 8 }}
                            sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}
                        >
                            <Typography variant="h6">ユーザー一覧</Typography>
                        </Grid2>
                        <Grid2
                            size={{ xs: 4, md: 4 }}
                            sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}
                        >
                            <Button variant="outlined" size="large" color="primary" onClick={handleUserAdd}>
                                ユーザーを追加する
                            </Button>
                        </Grid2>
                    </Grid2>

                    <Box sx={{ mb: 10 }}>
                        <MaterialReactTable
                            columns={columns}
                            data={userData}
                            columnFilterDisplayMode="popover"
                            enableColumnActions={false}
                            enableColumnResizing={false}
                            enableDensityToggle={false}
                            enableFullScreenToggle={false}
                            enableHiding={false}
                            localization={MRT_Localization_JA}
                            muiTableBodyRowProps={({ row }) => ({
                                onClick: () => handleUserDetail(row.original.id),
                                sx: { cursor: "pointer" },
                            })}
                            initialState={{ pagination: { pageIndex: 0, pageSize: 10 }, showGlobalFilter: true }}
                            muiPaginationProps={{
                                shape: "rounded",
                                color: "primary",
                            }}
                            paginationDisplayMode="pages"
                            positionGlobalFilter="left"
                            muiTablePaperProps={{ elevation: 0 }}
                        />
                    </Box>
                </Container>
            </Box>

            <Dialog maxWidth="sm" fullWidth open={open} onClose={handleClose}>
                <DialogTitle>
                    <IconButton sx={{ position: "absolute", right: 8, top: 8 }} onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ mt: 2, height: "700px" }}>
                    <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
                        <Tabs value={tabIndex} onChange={handleTabChange}>
                            <Tab label="所属部署" />
                            <Tab label="社員区分" />
                            <Tab label="職制区分" />
                        </Tabs>
                    </Box>

                    <Box sx={{ mt: 2 }}>
                        {tabIndex === 0 ? (
                            <Box
                                sx={{
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Box sx={{ minHeight: "430px", maxHeight: "430px", overflow: "auto", flexGrow: 1 }}>
                                    {divisionData.map((data, key) => (
                                        <Box key={key}>
                                            <Grid2 container spacing={2}>
                                                <Grid2
                                                    size={{ xs: 9, md: 9 }}
                                                    sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <Typography>{data}</Typography>
                                                </Grid2>
                                                <Grid2
                                                    size={{ xs: 3, md: 3 }}
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent: "flex-end",
                                                        alignItems: "center",
                                                        pr: 1,
                                                    }}
                                                >
                                                    <Button color="primary" onClick={handleDivisionEdit}>
                                                        編集する
                                                    </Button>
                                                </Grid2>
                                            </Grid2>
                                            <Divider sx={{ my: 1 }} />
                                        </Box>
                                    ))}
                                </Box>

                                <Box>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            mt: 5,
                                        }}
                                    >
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="large"
                                            sx={{ width: "400px" }}
                                            onClick={handleDivisionAdd}
                                        >
                                            所属部署を追加する
                                        </Button>
                                    </Box>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            mt: 5,
                                        }}
                                    >
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            size="large"
                                            onClick={handleClose}
                                            sx={{ width: "400px" }}
                                        >
                                            閉じる
                                        </Button>
                                    </Box>
                                </Box>
                            </Box>
                        ) : tabIndex === 1 ? (
                            <Box
                                sx={{
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Box sx={{ minHeight: "430px", maxHeight: "430px", overflow: "auto", flexGrow: 1 }}>
                                    {employeeData.map((data, key) => (
                                        <Box key={key}>
                                            <Grid2 container spacing={2}>
                                                <Grid2
                                                    size={{ xs: 9, md: 9 }}
                                                    sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <Typography>{data}</Typography>
                                                </Grid2>
                                                <Grid2
                                                    size={{ xs: 3, md: 3 }}
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent: "flex-end",
                                                        alignItems: "center",
                                                        pr: 1,
                                                    }}
                                                >
                                                    <Button color="primary" onClick={handleEmployeeEdit}>
                                                        編集する
                                                    </Button>
                                                </Grid2>
                                            </Grid2>
                                            <Divider sx={{ my: 1 }} />
                                        </Box>
                                    ))}
                                </Box>

                                <Box>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            mt: 5,
                                        }}
                                    >
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="large"
                                            sx={{ width: "400px" }}
                                            onClick={handleEmployeeAdd}
                                        >
                                            社員区分を追加する
                                        </Button>
                                    </Box>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            mt: 5,
                                        }}
                                    >
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            size="large"
                                            onClick={handleClose}
                                            sx={{ width: "400px" }}
                                        >
                                            閉じる
                                        </Button>
                                    </Box>
                                </Box>
                            </Box>
                        ) : (
                            <Box
                                sx={{
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Box sx={{ minHeight: "430px", maxHeight: "430px", overflow: "auto", flexGrow: 1 }}>
                                    {positionData.map((data, key) => (
                                        <Box key={key}>
                                            <Grid2 container spacing={2}>
                                                <Grid2
                                                    size={{ xs: 9, md: 9 }}
                                                    sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <Typography>{data}</Typography>
                                                </Grid2>
                                                <Grid2
                                                    size={{ xs: 3, md: 3 }}
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent: "flex-end",
                                                        alignItems: "center",
                                                        pr: 1,
                                                    }}
                                                >
                                                    <Button color="primary" onClick={handlePositionEdit}>
                                                        編集する
                                                    </Button>
                                                </Grid2>
                                            </Grid2>
                                            <Divider sx={{ my: 1 }} />
                                        </Box>
                                    ))}
                                </Box>

                                <Box>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            mt: 5,
                                        }}
                                    >
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="large"
                                            sx={{ width: "400px" }}
                                            onClick={handlePositionAdd}
                                        >
                                            職制区分を追加する
                                        </Button>
                                    </Box>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            mt: 5,
                                        }}
                                    >
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            size="large"
                                            onClick={handleClose}
                                            sx={{ width: "400px" }}
                                        >
                                            閉じる
                                        </Button>
                                    </Box>
                                </Box>
                            </Box>
                        )}
                    </Box>
                </DialogContent>
            </Dialog>

            <Dialog maxWidth="sm" fullWidth open={addDivisionOpen} onClose={handleAddDivisionClose}>
                <DialogTitle>
                    <IconButton sx={{ position: "absolute", right: 8, top: 8 }} onClick={handleAddDivisionClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent
                    sx={{
                        mt: 2,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Typography variant="h6">所属部署を追加</Typography>

                    <TextField
                        label="所属部署"
                        onChange={handleAddDivisionChange}
                        onBlur={handleAddDivisionBlur}
                        sx={{ width: "400px", mt: 2 }}
                    />
                    {addDivisionError !== "" && (
                        <Alert severity="error" sx={{ width: "400px", mt: 1 }}>
                            {addDivisionError}
                        </Alert>
                    )}

                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        sx={{ width: "400px", mt: 5 }}
                        onClick={handleDivisionRegister}
                    >
                        所属部署を登録する
                    </Button>

                    <Button
                        variant="outlined"
                        color="primary"
                        size="large"
                        sx={{ width: "400px", mt: 5, mb: 2 }}
                        onClick={handleAddDivisionClose}
                    >
                        所属部署一覧へ戻る
                    </Button>
                </DialogContent>
            </Dialog>

            <Dialog open={addDivisionCompleteOpen}>
                <DialogContent sx={{ mt: 2, display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <DialogContentText style={{ textAlign: "center", color: "#000" }}>
                        所属部署の登録が完了しました。
                    </DialogContentText>
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        sx={{ width: "400px", mt: 5 }}
                        onClick={handleAddDivisionClose}
                    >
                        OK
                    </Button>
                </DialogContent>
            </Dialog>

            <Dialog maxWidth="sm" fullWidth open={editDivisionOpen} onClose={handleEditDivisionClose}>
                <DialogTitle>
                    <IconButton sx={{ position: "absolute", right: 8, top: 8 }} onClick={handleEditDivisionClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent
                    sx={{
                        mt: 2,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Typography variant="h6">所属部署を編集</Typography>

                    <TextField
                        label="所属部署"
                        value={editDivisionData}
                        onChange={handleEditDivisionChange}
                        onBlur={handleEditDivisionBlur}
                        sx={{ width: "400px", mt: 2 }}
                    />
                    {editDivisionError !== "" && (
                        <Alert severity="error" sx={{ width: "400px", mt: 1 }}>
                            {editDivisionError}
                        </Alert>
                    )}

                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        sx={{ width: "400px", mt: 5 }}
                        onClick={handleDivisionUpdate}
                    >
                        所属部署を更新する
                    </Button>

                    <Button color="error" size="large" sx={{ mt: 5, mb: 5 }} onClick={handleDivisionDelete}>
                        所属部署を削除する
                    </Button>

                    <Button
                        variant="outlined"
                        color="primary"
                        size="large"
                        sx={{ width: "400px", mb: 2 }}
                        onClick={handleEditDivisionClose}
                    >
                        所属部署一覧へ戻る
                    </Button>
                </DialogContent>
            </Dialog>

            <Dialog open={editDivisionCompleteOpen}>
                <DialogContent sx={{ mt: 2, display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <DialogContentText style={{ textAlign: "center", color: "#000" }}>
                        所属部署の編集が完了しました。
                    </DialogContentText>
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        sx={{ width: "400px", mt: 5 }}
                        onClick={handleEditDivisionClose}
                    >
                        OK
                    </Button>
                </DialogContent>
            </Dialog>

            <Dialog open={deleteDivisionOpen} onClose={handleDeleteDivisionClose}>
                <DialogTitle>
                    <IconButton sx={{ position: "absolute", right: 8, top: 8 }} onClick={handleDeleteDivisionClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ mt: 2, display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <DialogContentText style={{ textAlign: "center", color: "#000" }}>
                        {editDivisionData}を削除します、よろしいですか？
                    </DialogContentText>
                    <Typography sx={{ textAlign: "left", mt: 3 }}>
                        ※ 所属しているユーザー・スタッフは「無所属」に変更されます。
                    </Typography>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 5 }}>
                        <Button
                            variant="outlined"
                            color="error"
                            size="large"
                            sx={{ width: "140px" }}
                            onClick={handleDivisionDeleteExecute}
                        >
                            削除する
                        </Button>

                        <Button
                            variant="contained"
                            color="error"
                            size="large"
                            sx={{ width: "140px", ml: 5 }}
                            onClick={handleDeleteDivisionClose}
                        >
                            キャンセル
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>

            <Dialog open={deleteDivisionCompleteOpen}>
                <DialogContent sx={{ mt: 2, display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <DialogContentText style={{ textAlign: "center", color: "#000" }}>
                        所属部署の削除が完了しました。
                    </DialogContentText>
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        sx={{ width: "400px", mt: 5 }}
                        onClick={handleDeleteDivisionCompleteClose}
                    >
                        OK
                    </Button>
                </DialogContent>
            </Dialog>

            <Dialog maxWidth="sm" fullWidth open={addEmployeeOpen} onClose={handleAddEmployeeClose}>
                <DialogTitle>
                    <IconButton sx={{ position: "absolute", right: 8, top: 8 }} onClick={handleAddEmployeeClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent
                    sx={{
                        mt: 2,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Typography variant="h6">社員区分を追加</Typography>

                    <TextField
                        label="社員区分"
                        onChange={handleAddEmployeeChange}
                        onBlur={handleAddEmployeeBlur}
                        sx={{ width: "400px", mt: 2 }}
                    />
                    {addEmployeeError !== "" && (
                        <Alert severity="error" sx={{ width: "400px", mt: 1 }}>
                            {addEmployeeError}
                        </Alert>
                    )}

                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        sx={{ width: "400px", mt: 5 }}
                        onClick={handleEmployeeRegister}
                    >
                        社員区分を登録する
                    </Button>

                    <Button
                        variant="outlined"
                        color="primary"
                        size="large"
                        sx={{ width: "400px", mt: 5, mb: 2 }}
                        onClick={handleAddEmployeeClose}
                    >
                        社員区分一覧へ戻る
                    </Button>
                </DialogContent>
            </Dialog>

            <Dialog open={addEmployeeCompleteOpen}>
                <DialogContent sx={{ mt: 2, display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <DialogContentText style={{ textAlign: "center", color: "#000" }}>
                        社員区分の登録が完了しました。
                    </DialogContentText>
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        sx={{ width: "400px", mt: 5 }}
                        onClick={handleAddEmployeeClose}
                    >
                        OK
                    </Button>
                </DialogContent>
            </Dialog>

            <Dialog maxWidth="sm" fullWidth open={editEmployeeOpen} onClose={handleEditEmployeeClose}>
                <DialogTitle>
                    <IconButton sx={{ position: "absolute", right: 8, top: 8 }} onClick={handleEditEmployeeClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent
                    sx={{
                        mt: 2,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Typography variant="h6">社員区分を編集</Typography>

                    <TextField
                        label="社員区分"
                        value={editEmployeeData}
                        onChange={handleEditEmployeeChange}
                        onBlur={handleEditEmployeeBlur}
                        sx={{ width: "400px", mt: 2 }}
                    />
                    {editEmployeeError !== "" && (
                        <Alert severity="error" sx={{ width: "400px", mt: 1 }}>
                            {editEmployeeError}
                        </Alert>
                    )}

                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        sx={{ width: "400px", mt: 5 }}
                        onClick={handleEmployeeUpdate}
                    >
                        社員区分を更新する
                    </Button>

                    <Button color="error" size="large" sx={{ mt: 5, mb: 5 }} onClick={handleEmployeeDelete}>
                        社員区分を削除する
                    </Button>

                    <Button
                        variant="outlined"
                        color="primary"
                        size="large"
                        sx={{ width: "400px", mb: 2 }}
                        onClick={handleEditEmployeeClose}
                    >
                        社員区分一覧へ戻る
                    </Button>
                </DialogContent>
            </Dialog>

            <Dialog open={editEmployeeCompleteOpen}>
                <DialogContent sx={{ mt: 2, display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <DialogContentText style={{ textAlign: "center", color: "#000" }}>
                        社員区分の編集が完了しました。
                    </DialogContentText>
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        sx={{ width: "400px", mt: 5 }}
                        onClick={handleEditEmployeeClose}
                    >
                        OK
                    </Button>
                </DialogContent>
            </Dialog>

            <Dialog open={deleteEmployeeOpen} onClose={handleDeleteEmployeeClose}>
                <DialogTitle>
                    <IconButton sx={{ position: "absolute", right: 8, top: 8 }} onClick={handleDeleteEmployeeClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ mt: 2, display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <DialogContentText style={{ textAlign: "center", color: "#000" }}>
                        {editEmployeeData}を削除します、よろしいですか？
                    </DialogContentText>
                    <Typography sx={{ textAlign: "left", mt: 3 }}>
                        ※ 削除する社員区分が登録されているスタッフは「なし」に変更されます。
                    </Typography>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 5 }}>
                        <Button
                            variant="outlined"
                            color="error"
                            size="large"
                            sx={{ width: "140px" }}
                            onClick={handleEmployeeDeleteExecute}
                        >
                            削除する
                        </Button>

                        <Button
                            variant="contained"
                            color="error"
                            size="large"
                            sx={{ width: "140px", ml: 5 }}
                            onClick={handleDeleteEmployeeClose}
                        >
                            キャンセル
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>

            <Dialog open={deleteEmployeeCompleteOpen}>
                <DialogContent sx={{ mt: 2, display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <DialogContentText style={{ textAlign: "center", color: "#000" }}>
                        社員区分の削除が完了しました。
                    </DialogContentText>
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        sx={{ width: "400px", mt: 5 }}
                        onClick={handleDeleteEmployeeCompleteClose}
                    >
                        OK
                    </Button>
                </DialogContent>
            </Dialog>

            <Dialog maxWidth="sm" fullWidth open={addPositionOpen} onClose={handleAddPositionClose}>
                <DialogTitle>
                    <IconButton sx={{ position: "absolute", right: 8, top: 8 }} onClick={handleAddPositionClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent
                    sx={{
                        mt: 2,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Typography variant="h6">職制区分を追加</Typography>

                    <TextField
                        label="職制区分"
                        onChange={handleAddPositionChange}
                        onBlur={handleAddPositionBlur}
                        sx={{ width: "400px", mt: 2 }}
                    />
                    {addPositionError !== "" && (
                        <Alert severity="error" sx={{ width: "400px", mt: 1 }}>
                            {addPositionError}
                        </Alert>
                    )}

                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        sx={{ width: "400px", mt: 5 }}
                        onClick={handlePositionRegister}
                    >
                        職制区分を登録する
                    </Button>

                    <Button
                        variant="outlined"
                        color="primary"
                        size="large"
                        sx={{ width: "400px", mt: 5, mb: 2 }}
                        onClick={handleAddPositionClose}
                    >
                        職制区分一覧へ戻る
                    </Button>
                </DialogContent>
            </Dialog>

            <Dialog open={addPositionCompleteOpen}>
                <DialogContent sx={{ mt: 2, display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <DialogContentText style={{ textAlign: "center", color: "#000" }}>
                        職制区分の登録が完了しました。
                    </DialogContentText>
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        sx={{ width: "400px", mt: 5 }}
                        onClick={handleAddPositionClose}
                    >
                        OK
                    </Button>
                </DialogContent>
            </Dialog>

            <Dialog maxWidth="sm" fullWidth open={editPositionOpen} onClose={handleEditPositionClose}>
                <DialogTitle>
                    <IconButton sx={{ position: "absolute", right: 8, top: 8 }} onClick={handleEditPositionClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent
                    sx={{
                        mt: 2,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Typography variant="h6">職制区分を編集</Typography>

                    <TextField
                        label="職制区分"
                        value={editPositionData}
                        onChange={handleEditPositionChange}
                        onBlur={handleEditPositionBlur}
                        sx={{ width: "400px", mt: 2 }}
                    />
                    {editPositionError !== "" && (
                        <Alert severity="error" sx={{ width: "400px", mt: 1 }}>
                            {editPositionError}
                        </Alert>
                    )}

                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        sx={{ width: "400px", mt: 5 }}
                        onClick={handlePositionUpdate}
                    >
                        職制区分を更新する
                    </Button>

                    <Button color="error" size="large" sx={{ mt: 5, mb: 5 }} onClick={handlePositionDelete}>
                        職制区分を削除する
                    </Button>

                    <Button
                        variant="outlined"
                        color="primary"
                        size="large"
                        sx={{ width: "400px", mb: 2 }}
                        onClick={handleEditPositionClose}
                    >
                        職制区分一覧へ戻る
                    </Button>
                </DialogContent>
            </Dialog>

            <Dialog open={editPositionCompleteOpen}>
                <DialogContent sx={{ mt: 2, display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <DialogContentText style={{ textAlign: "center", color: "#000" }}>
                        職制区分の編集が完了しました。
                    </DialogContentText>
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        sx={{ width: "400px", mt: 5 }}
                        onClick={handleEditPositionClose}
                    >
                        OK
                    </Button>
                </DialogContent>
            </Dialog>

            <Dialog open={deletePositionOpen} onClose={handleDeletePositionClose}>
                <DialogTitle>
                    <IconButton sx={{ position: "absolute", right: 8, top: 8 }} onClick={handleDeletePositionClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ mt: 2, display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <DialogContentText style={{ textAlign: "center", color: "#000" }}>
                        {editPositionData}を削除します、よろしいですか？
                    </DialogContentText>
                    <Typography sx={{ textAlign: "left", mt: 3 }}>
                        ※ 削除する職制区分が登録されているスタッフは「なし」に変更されます。
                    </Typography>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 5 }}>
                        <Button
                            variant="outlined"
                            color="error"
                            size="large"
                            sx={{ width: "140px" }}
                            onClick={handlePositionDeleteExecute}
                        >
                            削除する
                        </Button>

                        <Button
                            variant="contained"
                            color="error"
                            size="large"
                            sx={{ width: "140px", ml: 5 }}
                            onClick={handleDeletePositionClose}
                        >
                            キャンセル
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>

            <Dialog open={deletePositionCompleteOpen}>
                <DialogContent sx={{ mt: 2, display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <DialogContentText style={{ textAlign: "center", color: "#000" }}>
                        職制区分の削除が完了しました。
                    </DialogContentText>
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        sx={{ width: "400px", mt: 5 }}
                        onClick={handleDeletePositionCompleteClose}
                    >
                        OK
                    </Button>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default Company;
