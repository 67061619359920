import React, { useEffect, useState } from "react";

import { Box } from "@mui/system";
import {
    AppBar,
    Toolbar,
    IconButton,
    Drawer,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Typography,
    Tooltip,
    Divider,
    useMediaQuery,
    Grid2,
} from "@mui/material";

import MenuIcon from "@mui/icons-material/Menu";
import PersonIcon from "@mui/icons-material/Person";
import FactoryIcon from "@mui/icons-material/Factory";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import AssignmentIcon from "@mui/icons-material/Assignment";
import CurrencyYenIcon from "@mui/icons-material/CurrencyYen";
import ReduceCapacityIcon from "@mui/icons-material/ReduceCapacity";
import AssuredWorkloadIcon from "@mui/icons-material/AssuredWorkload";
import PaymentIcon from "@mui/icons-material/Payment";
import GroupIcon from "@mui/icons-material/Group";
import ApartmentIcon from "@mui/icons-material/Apartment";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import LogoutIcon from "@mui/icons-material/Logout";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import CloseIcon from "@mui/icons-material/Close";
import LockResetIcon from "@mui/icons-material/LockReset";
import HelpIcon from "@mui/icons-material/Help";

import { useLocation, useNavigate } from "react-router-dom";

import { getAuth, signOut } from "firebase/auth";
// import { db } from "../firebase";
// import { collection, getDocs, query, where, limit } from "firebase/firestore";

function Header({ emitVal }) {
    const location = useLocation();

    const notShowHeader = () => {
        if (
            location.pathname === "/" ||
            location.pathname === "/reset-password" ||
            location.pathname === "/reset-password/complete" ||
            location.pathname === "/password-configure"
        ) {
            return false;
        }

        return true;
    };

    const isSP = useMediaQuery("(max-width: 600px)");
    const headerHeight = isSP ? "56px" : "64px";

    const navigate = useNavigate();
    // const headerImage = process.env.PUBLIC_URL + "/images/logo.png";
    const auth = getAuth();

    useEffect(() => {
        const unsubscribed = getAuth().onAuthStateChanged(async (userLogin) => {
            // const uid = userLogin.uid;
            // await fetchUserData(uid);
        });
        return () => unsubscribed();
    }, []);

    const fetchUserData = async (uid) => {};

    const handleLink = (path) => {
        navigate(path);
    };

    const handleTransPrivacy = () => {
        window.open("https://hr-ms.jp/privacy-policy", "_blank", "noopener,noreferrer");
    };

    const [open, setOpen] = React.useState(false);
    // メニューを開く
    const handleClickOpen = () => {
        setOpen(true);
    };

    // メニューを閉じる
    const handleClose = () => {
        setOpen(false);
    };

    const menuItems = [
        {
            label: "メニュー",
        },
        {
            label: "会社情報",
            icon: <ApartmentIcon />,
        },
        {
            label: "マイページ",
            icon: <AssignmentIndIcon />,
        },
        {
            label: "パスワード変更",
            icon: <LockResetIcon />,
        },
        {
            label: "操作マニュアル",
            icon: <HelpIcon />,
        },
        {
            label: "ログアウト",
            icon: <LogoutIcon />,
        },
    ];

    const menuItemClick = (label) => {
        if (label === "会社情報") {
            handleClose();
            setSelectedIndex(undefined);
            window.scrollTo(0, 0);
            navigate("/company");
        } else if (label === "マイページ") {
            handleClose();
            setSelectedIndex(undefined);
            window.scrollTo(0, 0);
            // navigate("/user/{uid}");
            navigate("/user/detail");
        } else if (label === "パスワード変更") {
            handleClose();
            setSelectedIndex(undefined);
            window.scrollTo(0, 0);
            navigate("/password-edit");
        } else if (label === "操作マニュアル") {
            handleClose();
            setSelectedIndex(undefined);
            window.scrollTo(0, 0);
            navigate("/manual");
        } else if (label === "ログアウト") {
            handleLogout();
        }
    };

    // ログアウト処理を実行
    const handleLogout = () => {
        signOut(auth);
        setOpen(false);
        window.scrollTo(0, 0);
        navigate("/", { replace: true });
    };

    const [selectedIndex, setSelectedIndex] = useState(undefined);
    const handleListItemClick = (index) => {
        setSelectedIndex(index);

        if (index === 0) {
            window.scrollTo(0, 0);
            navigate("/dispatch");
        } else if (index === 1) {
            window.scrollTo(0, 0);
            navigate("/staff");
        } else if (index === 2) {
            window.scrollTo(0, 0);
            navigate("/dormitory");
        } else if (index === 3) {
            window.scrollTo(0, 0);
            navigate("/attendance");
        } else if (index === 4) {
            window.scrollTo(0, 0);
            navigate("/bill");
        } else if (index === 5) {
            window.scrollTo(0, 0);
            navigate("/apply");
        } else if (index === 6) {
            window.scrollTo(0, 0);
            navigate("/audit");
        } else if (index === 7) {
            window.scrollTo(0, 0);
            navigate("/dailypay");
        } else if (index === 8) {
            window.scrollTo(0, 0);
            navigate("/manager");
        }
    };

    const [isMinimized, setIsMinimized] = useState(false);
    const toggleMinimize = () => {
        setIsMinimized(!isMinimized);
        emitVal(!isMinimized);
    };

    useEffect(() => {
        const path = location.pathname.split("/")[1];
        if (path === "dispatch") {
            setSelectedIndex(0);
        } else if (path === "staff") {
            setSelectedIndex(1);
        } else if (path === "dormitory") {
            setSelectedIndex(2);
        } else if (path === "attendance") {
            setSelectedIndex(3);
        } else if (path === "bill") {
            setSelectedIndex(4);
        } else if (path === "apply") {
            setSelectedIndex(5);
        } else if (path === "audit") {
            setSelectedIndex(6);
        } else if (path === "dailypay") {
            setSelectedIndex(7);
        } else if (path === "manager") {
            setSelectedIndex(8);
        } else {
            setSelectedIndex(undefined);
        }
    }, [location.pathname]);

    return (
        <div>
            {notShowHeader() && (
                <>
                    <Box sx={{ flexGrow: 1 }}>
                        <AppBar position="fixed" style={{ backgroundColor: "#1f5356", color: "#FFFFFF" }}>
                            <Box>
                                <Toolbar>
                                    <Box sx={{ flexGrow: 1 }} style={{ display: "flex", alignItem: "center" }}>
                                        <IconButton
                                            size="large"
                                            edge="start"
                                            color="inherit"
                                            aria-label="menu"
                                            onClick={toggleMinimize}
                                        >
                                            <MenuIcon />
                                        </IconButton>
                                        <Typography
                                            variant="h6"
                                            sx={{ ml: 2 }}
                                            style={{ display: "flex", alignItems: "center" }}
                                        >
                                            [LOGO]
                                        </Typography>
                                        {/* 
                                <img
                                    src={headerImage}
                                    alt="HR system"
                                    className="header-image"
                                />
                                */}
                                    </Box>
                                    <Box>
                                        <IconButton
                                            size="large"
                                            edge="start"
                                            color="inherit"
                                            aria-label="account"
                                            style={{ marginRight: "-12px" }}
                                            onClick={handleClickOpen}
                                        >
                                            <PersonIcon />
                                        </IconButton>
                                    </Box>
                                </Toolbar>
                            </Box>
                        </AppBar>
                    </Box>

                    <Box
                        sx={{
                            position: "fixed",
                            left: 0,
                            top: headerHeight,
                            bottom: 0,
                            width: isMinimized ? "48px" : "240px",
                            backgroundColor: "#f0f0f0",
                            borderRight: "1px solid #ddd",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                        }}
                    >
                        <List
                            sx={{
                                height: `calc(100% - ${headerHeight}px)`,
                                flexGrow: 1,
                            }}
                        >
                            {/* メニュー項目 */}
                            <Box sx={{ flexDirection: "column", justifyContent: "center" }}>
                                {isMinimized ? (
                                    <>
                                        <Tooltip title="派遣先管理" placement="right">
                                            <IconButton
                                                color={selectedIndex === 0 ? "primary" : "defalut"}
                                                onClick={() => handleListItemClick(0)}
                                            >
                                                <FactoryIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="スタッフ管理" placement="right">
                                            <IconButton
                                                color={selectedIndex === 1 ? "primary" : "defalut"}
                                                onClick={() => handleListItemClick(1)}
                                            >
                                                <EmojiPeopleIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="社宅管理" placement="right">
                                            <IconButton
                                                color={selectedIndex === 2 ? "primary" : "defalut"}
                                                onClick={() => handleListItemClick(2)}
                                            >
                                                <HomeWorkIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="勤怠管理" placement="right">
                                            <IconButton
                                                color={selectedIndex === 3 ? "primary" : "defalut"}
                                                onClick={() => handleListItemClick(3)}
                                            >
                                                <AssignmentIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="請求管理" placement="right">
                                            <IconButton
                                                color={selectedIndex === 4 ? "primary" : "defalut"}
                                                onClick={() => handleListItemClick(4)}
                                            >
                                                <CurrencyYenIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="応募者管理" placement="right">
                                            <IconButton
                                                color={selectedIndex === 5 ? "primary" : "defalut"}
                                                onClick={() => handleListItemClick(5)}
                                            >
                                                <ReduceCapacityIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="監査対応" placement="right">
                                            <IconButton
                                                color={selectedIndex === 6 ? "primary" : "defalut"}
                                                onClick={() => handleListItemClick(6)}
                                            >
                                                <AssuredWorkloadIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="日払い管理" placement="right">
                                            <IconButton
                                                color={selectedIndex === 7 ? "primary" : "defalut"}
                                                onClick={() => handleListItemClick(7)}
                                            >
                                                <PaymentIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="派遣元責任者管理" placement="right">
                                            <IconButton
                                                color={selectedIndex === 8 ? "primary" : "defalut"}
                                                onClick={() => handleListItemClick(8)}
                                            >
                                                <GroupIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </>
                                ) : (
                                    <>
                                        <ListItemButton
                                            selected={selectedIndex === 0}
                                            onClick={() => handleListItemClick(0)}
                                        >
                                            <ListItemIcon>
                                                <FactoryIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="派遣先管理" />
                                        </ListItemButton>
                                        <ListItemButton
                                            selected={selectedIndex === 1}
                                            onClick={() => handleListItemClick(1)}
                                        >
                                            <ListItemIcon>
                                                <EmojiPeopleIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="スタッフ管理" />
                                        </ListItemButton>
                                        <ListItemButton
                                            selected={selectedIndex === 2}
                                            onClick={() => handleListItemClick(2)}
                                        >
                                            <ListItemIcon>
                                                <HomeWorkIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="社宅管理" />
                                        </ListItemButton>
                                        <ListItemButton
                                            selected={selectedIndex === 3}
                                            onClick={() => handleListItemClick(3)}
                                        >
                                            <ListItemIcon>
                                                <AssignmentIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="勤怠管理" />
                                        </ListItemButton>
                                        <ListItemButton
                                            selected={selectedIndex === 4}
                                            onClick={() => handleListItemClick(4)}
                                        >
                                            <ListItemIcon>
                                                <CurrencyYenIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="請求管理" />
                                        </ListItemButton>
                                        <ListItemButton
                                            selected={selectedIndex === 5}
                                            onClick={() => handleListItemClick(5)}
                                        >
                                            <ListItemIcon>
                                                <ReduceCapacityIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="応募者管理" />
                                        </ListItemButton>
                                        <ListItemButton
                                            selected={selectedIndex === 6}
                                            onClick={() => handleListItemClick(6)}
                                        >
                                            <ListItemIcon>
                                                <AssuredWorkloadIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="監査対応" />
                                        </ListItemButton>
                                        <ListItemButton
                                            selected={selectedIndex === 7}
                                            onClick={() => handleListItemClick(7)}
                                        >
                                            <ListItemIcon>
                                                <PaymentIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="日払い管理" />
                                        </ListItemButton>
                                        <ListItemButton
                                            selected={selectedIndex === 8}
                                            onClick={() => handleListItemClick(8)}
                                        >
                                            <ListItemIcon>
                                                <GroupIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="派遣元責任者管理" />
                                        </ListItemButton>
                                    </>
                                )}
                            </Box>
                        </List>

                        {/* メニュー展開 */}
                        <List>
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                {isMinimized ? (
                                    <Tooltip title="メニューを開く" placement="right">
                                        <IconButton onClick={toggleMinimize}>
                                            <KeyboardArrowRightIcon />
                                        </IconButton>
                                    </Tooltip>
                                ) : (
                                    <Box sx={{ width: "100%" }}>
                                        <Divider sx={{ mb: 1 }} />
                                        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 1, mx: 2 }}>
                                            <Typography
                                                variant="caption"
                                                sx={{ textDecoration: "underline", mr: 2 }}
                                                style={{ cursor: "pointer" }}
                                                onClick={() => handleLink("terms")}
                                            >
                                                利用規約
                                            </Typography>
                                            <Typography
                                                variant="caption"
                                                sx={{ textDecoration: "underline" }}
                                                style={{ cursor: "pointer" }}
                                                onClick={() => handleTransPrivacy()}
                                            >
                                                プライバシーポリシー
                                            </Typography>
                                        </Box>
                                        <Typography variant="caption" style={{ color: "#666" }}>
                                            © HR Managed Service Inc.
                                        </Typography>
                                    </Box>
                                )}
                            </Box>
                        </List>
                    </Box>

                    <Drawer anchor="right" open={open} onClose={handleClose}>
                        <Box sx={{ width: "250px" }}>
                            <Grid2 container>
                                <Grid2 sx={{ ml: 1, mt: 1 }} size={{ xs: 8, md: 8 }}>
                                    <Typography variant="body2">部署名</Typography>
                                    <Typography variant="body2">ユーザー名</Typography>
                                </Grid2>
                                <Grid2
                                    size={{ xs: 3, md: 3 }}
                                    sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}
                                >
                                    <IconButton onClick={handleClose}>
                                        <CloseIcon />
                                    </IconButton>
                                </Grid2>
                            </Grid2>
                            <List>
                                {menuItems.map((item, index) => (
                                    <React.Fragment key={item.label}>
                                        {index === 4 && <Divider />}
                                        <ListItemButton
                                            disabled={item.label === "メニュー"}
                                            onClick={() => menuItemClick(item.label)}
                                        >
                                            {item.label !== "メニュー" && <ListItemIcon>{item.icon}</ListItemIcon>}
                                            <ListItemText primary={item.label} />
                                        </ListItemButton>
                                        {index === 4 && <Divider />}
                                    </React.Fragment>
                                ))}
                            </List>
                        </Box>
                    </Drawer>
                </>
            )}
        </div>
    );
}

export default Header;
