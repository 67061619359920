import React, { useState } from "react";
import { Container, Typography, Box, Button, Grid2, Divider } from "@mui/material";

import { useNavigate } from "react-router-dom";

import { Helmet } from "react-helmet-async";

const UserDetail = ({ val }) => {
    const navigate = useNavigate();

    const [myData, setMyData] = useState({
        myName: { title: "ユーザー名", value: "高坂 穂乃果" },
        myPhone: { title: "電話番号", value: "090-1234-5678" },
        myEmail: { title: "メールアドレス", value: "h-kousaka@hr-ms.jp" },
        myDivision: { title: "部署", value: "営業部" },
        myPosition: { title: "役職", value: "部長" },
        myCharge: {
            title: "担当業務",
            value: [
                { id: 1, name: "派遣元責任者" },
                { id: 2, name: "苦情申し出先" },
            ],
        },
        myAuthority: { title: "権限", value: "管理者" },
    });

    const handleMyDataEdit = () => {
        // 操作している人が本人または、管理者の場合のみ編集画面に遷移する
        window.scrollTo(0, 0);
        navigate("/user/detail/edit");
    };

    const handleReturn = () => {
        window.scrollTo(0, 0);
        navigate("/company");
    };

    return (
        <>
            <Helmet>
                <title>ユーザー情報詳細 - HR System (仮)</title>
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>

            <Box sx={{ pl: val === true ? 6 : 30 }}>
                <Container maxWidth="false" sx={{ mt: 10 }}>
                    <Grid2 container sx={{ mb: 3 }}>
                        <Grid2
                            size={{ xs: 8, md: 8 }}
                            sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}
                        >
                            <Typography variant="h6">ユーザー情報</Typography>
                        </Grid2>
                        <Grid2
                            size={{ xs: 4, md: 4 }}
                            sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}
                        >
                            <Button variant="outlined" size="large" color="primary" onClick={handleMyDataEdit}>
                                ユーザー情報を編集する
                            </Button>
                        </Grid2>
                    </Grid2>

                    {Object.entries(myData).map(([key, data]) => (
                        <Box key={key}>
                            <Grid2 container spacing={2}>
                                <Grid2
                                    size={{ xs: 5, md: 5 }}
                                    sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}
                                >
                                    <Typography fontWeight="bold">{data.title}</Typography>
                                </Grid2>
                                <Grid2
                                    size={{ xs: 7, md: 7 }}
                                    sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}
                                >
                                    <Typography>
                                        {key === "myCharge"
                                            ? data.value.map((item) => item.name).join(", ")
                                            : data.value}
                                    </Typography>
                                </Grid2>
                            </Grid2>
                            <Divider sx={{ my: 1 }} />
                        </Box>
                    ))}

                    <Box sx={{ mt: 5, mb: 10, display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Button
                            variant="outlined"
                            size="large"
                            color="primary"
                            sx={{ width: "600px" }}
                            onClick={handleReturn}
                        >
                            会社情報へ戻る
                        </Button>
                    </Box>
                </Container>
            </Box>
        </>
    );
};

export default UserDetail;
