import React, { useState } from "react";
import {
    Container,
    Typography,
    Box,
    Button,
    TextField,
    Alert,
    Grid2,
    FormControl,
    Select,
    MenuItem,
    InputAdornment,
    Checkbox,
    FormControlLabel,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Divider,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

import { useNavigate } from "react-router-dom";

import { Helmet } from "react-helmet-async";

const CompanyEdit = ({ val }) => {
    const navigate = useNavigate();

    const [companyData, setCompanyData] = useState({
        companyName: { title: "企業名", value: "HRマネージドサービス株式会社" },
        companyRepresentative: { title: "代表者の役職と氏名", value1: "代表取締役", value2: "絢瀬 絵里" },
        companyAddress: {
            title: "住所",
            post: "1000000",
            prefecture: "東京都",
            city: "千代田区",
            other: "千代田1-1-1",
            building: "皇居",
        },
        dispatchBusiness: { title: "労働者派遣事業", value1: 1, value2: "13", value3: "123456" },
        dispatchAmount: { title: "労働者派遣に関する料金の平均額", value: "35000" },
        laborAgreement: { title: "労使協定", value: true, startDate: "2024/01/01", endDate: "2024/12/31" },
        payment: {
            title: "支払条件",
            closing: "締め日",
            closingValue: "末日",
            date: "支払日",
            dateValue: "翌月20日",
            method: "支払方法",
            methodValue: "銀行振込",
        },
    });

    const [companyDataError, setCompanyDataError] = useState({
        companyName: "",
        companyRepresentative1: "",
        companyRepresentative2: "",
        companyAddressPost: "",
        companyAddressPrefecture: "",
        companyAddressCity: "",
        companyAddressOther: "",
        dispatchBusiness: "",
        dispatchBusiness2: "",
        dispatchBusiness3: "",
        dispatchAmount: "",
        laborAgreement: "",
        laborAgreementStartDate: "",
        laborAgreementEndDate: "",
        paymentClosing: "",
        paymentDate: "",
        paymentMethod: "",
    });

    const [validation, setValidation] = useState("");

    const handleCompanyNameChange = (e) => {
        setCompanyData({ ...companyData, companyName: { ...companyData.companyName, value: e.target.value } });
    };
    const handleCompanyNameBlur = () => {
        setCompanyDataError({
            ...companyDataError,
            companyName: companyData.companyName.value === "" ? "企業名を入力してください" : "",
        });
    };

    const handleCompanyRepresentative1Change = (e) => {
        setCompanyData({
            ...companyData,
            companyRepresentative: { ...companyData.companyRepresentative, value1: e.target.value },
        });
    };
    const handleCompanyRepresentative1Blur = () => {
        setCompanyDataError({
            ...companyDataError,
            companyRepresentative1:
                companyData.companyRepresentative.value1 === "" ? "代表の役職を入力してください" : "",
        });
    };

    const handleCompanyRepresentative2Change = (e) => {
        setCompanyData({
            ...companyData,
            companyRepresentative: { ...companyData.companyRepresentative, value2: e.target.value },
        });
    };
    const handleCompanyRepresentative2Blur = () => {
        setCompanyDataError({
            ...companyDataError,
            companyRepresentative2:
                companyData.companyRepresentative.value2 === "" ? "代表の氏名を入力してください" : "",
        });
    };

    const handleCompanyAddressPostChange = (e) => {
        const value = e.target.value.replace(/[^0-9]/g, "");
        setCompanyData({ ...companyData, companyAddress: { ...companyData.companyAddress, post: value } });
    };
    const handleCompanyAddressPostBlur = () => {
        setCompanyDataError({
            ...companyDataError,
            companyAddressPost: companyData.companyAddress.post === "" ? "郵便番号を入力してください" : "",
        });
    };

    const handleCompanyAddressPrefectureChange = (e) => {
        setCompanyData({
            ...companyData,
            companyAddress: { ...companyData.companyAddress, prefecture: e.target.value },
        });
    };
    const handleCompanyAddressPrefectureBlur = () => {
        setCompanyDataError({
            ...companyDataError,
            companyAddressPrefecture: companyData.companyAddress.prefecture === "" ? "都道府県を選択してください" : "",
        });
    };

    const handleCompanyAddressCityChange = (e) => {
        setCompanyData({ ...companyData, companyAddress: { ...companyData.companyAddress, city: e.target.value } });
    };
    const handleCompanyAddressCityBlur = () => {
        setCompanyDataError({
            ...companyDataError,
            companyAddressCity: companyData.companyAddress.city === "" ? "市区町村を入力してください" : "",
        });
    };

    const handleCompanyAddressOtherChange = (e) => {
        setCompanyData({ ...companyData, companyAddress: { ...companyData.companyAddress, other: e.target.value } });
    };
    const handleCompanyAddressOtherBlur = () => {
        setCompanyDataError({
            ...companyDataError,
            companyAddressOther: companyData.companyAddress.other === "" ? "上記以降の住所を入力してください" : "",
        });
    };

    const handleCompanyAddressBuildingChange = (e) => {
        setCompanyData({ ...companyData, companyAddress: { ...companyData.companyAddress, building: e.target.value } });
    };

    const handleDispatchBusiness1Change = (e) => {
        setCompanyData({
            ...companyData,
            dispatchBusiness: { ...companyData.dispatchBusiness, value1: e.target.value },
        });
    };
    const handleDispatchBusiness2Change = (e) => {
        const value = e.target.value.replace(/[^0-9]/g, "");
        setCompanyData({
            ...companyData,
            dispatchBusiness: { ...companyData.dispatchBusiness, value2: value },
        });
    };
    const handleDispatchBusiness3Change = (e) => {
        const value = e.target.value.replace(/[^0-9]/g, "");
        setCompanyData({
            ...companyData,
            dispatchBusiness: { ...companyData.dispatchBusiness, value3: value },
        });
    };
    const handleDispatchBusinessBlur = () => {
        setCompanyDataError({
            ...companyDataError,
            dispatchBusiness:
                companyData.dispatchBusiness.value2 === "" || companyData.dispatchBusiness.value3 === ""
                    ? "労働者派遣事業許可番号を入力してください"
                    : "",
            dispatchBusiness2: companyData.dispatchBusiness.value2 === "" ? "error" : "",
            dispatchBusiness3: companyData.dispatchBusiness.value3 === "" ? "error" : "",
        });
    };

    const handleDispatchAmountChange = (e) => {
        const value = e.target.value.replace(/[^0-9]/g, "");
        setCompanyData({ ...companyData, dispatchAmount: { ...companyData.dispatchAmount, value: value } });
    };
    const handleDispatchAmountBlur = () => {
        setCompanyDataError({
            ...companyDataError,
            dispatchAmount:
                companyData.dispatchAmount.value === "" ? "労働者派遣に関する料金の平均額を入力してください" : "",
        });
    };

    const handleLaborAgreementChange = (e) => {
        if (e.target.checked === false) {
            setCompanyData({
                ...companyData,
                laborAgreement: { ...companyData.laborAgreement, value: false, startDate: "", endDate: "" },
            });
            setCompanyDataError({
                ...companyDataError,
                laborAgreement: "",
                laborAgreementStartDate: "",
                laborAgreementEndDate: "",
            });
        } else {
            setCompanyData({
                ...companyData,
                laborAgreement: { ...companyData.laborAgreement, value: true },
            });
        }

        if (e.target.checked === true) {
            setCompanyDataError({
                ...companyDataError,
                laborAgreement:
                    companyData.laborAgreement.startDate === "" || companyData.laborAgreement.endDate === ""
                        ? "期間を入力してください"
                        : "",
                laborAgreementStartDate: companyData.laborAgreement.startDate === "" ? "error" : "",
                laborAgreementEndDate: companyData.laborAgreement.endDate === "" ? "error" : "",
            });
        }
    };

    const handleLaborAgreementStartDateChange = (e) => {
        setCompanyData({
            ...companyData,
            laborAgreement: { ...companyData.laborAgreement, startDate: e.target.value },
        });
    };
    const handleLaborAgreementEndDateChange = (e) => {
        setCompanyData({ ...companyData, laborAgreement: { ...companyData.laborAgreement, endDate: e.target.value } });
    };
    const handleLaborAgreementBlur = () => {
        if (companyData.laborAgreement.value === true) {
            setCompanyDataError({
                ...companyDataError,
                laborAgreement:
                    companyData.laborAgreement.startDate === "" || companyData.laborAgreement.endDate === ""
                        ? "期間を入力してください"
                        : "",
                laborAgreementStartDate: companyData.laborAgreement.startDate === "" ? "error" : "",
                laborAgreementEndDate: companyData.laborAgreement.endDate === "" ? "error" : "",
            });
        }
    };

    const handlePaymentClosingChange = (e) => {
        setCompanyData({ ...companyData, payment: { ...companyData.payment, closingValue: e.target.value } });
    };
    const handlePaymentClosingBlur = () => {
        setCompanyDataError({
            ...companyDataError,
            paymentClosing: companyData.payment.closingValue === "" ? "締め日を入力してください" : "",
        });
    };

    const handlePaymentDateChange = (e) => {
        setCompanyData({ ...companyData, payment: { ...companyData.payment, dateValue: e.target.value } });
    };
    const handlePaymentDateBlur = () => {
        setCompanyDataError({
            ...companyDataError,
            paymentDate: companyData.payment.dateValue === "" ? "支払日を入力してください" : "",
        });
    };

    const handlePaymentMethodChange = (e) => {
        setCompanyData({ ...companyData, payment: { ...companyData.payment, methodValue: e.target.value } });
    };
    const handlePaymentMethodBlur = () => {
        setCompanyDataError({
            ...companyDataError,
            paymentMethod: companyData.payment.methodValue === "" ? "支払方法を入力してください" : "",
        });
    };

    const handleEditConfirm = () => {
        if (
            companyDataError.companyName === "" &&
            companyDataError.companyRepresentative1 === "" &&
            companyDataError.companyRepresentative2 === "" &&
            companyDataError.companyAddressPost === "" &&
            companyDataError.companyAddressPrefecture === "" &&
            companyDataError.companyAddressCity === "" &&
            companyDataError.companyAddressOther === "" &&
            companyDataError.dispatchBusiness === "" &&
            companyDataError.dispatchBusiness2 === "" &&
            companyDataError.dispatchBusiness3 === "" &&
            companyDataError.dispatchAmount === "" &&
            companyDataError.laborAgreement === "" &&
            companyDataError.laborAgreementStartDate === "" &&
            companyDataError.laborAgreementEndDate === "" &&
            companyDataError.paymentClosing === "" &&
            companyDataError.paymentDate === "" &&
            companyDataError.paymentMethod === ""
        ) {
            setValidation("");
            setOpen(true);
        } else {
            setValidation("入力内容に不備があります");
        }
    };

    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const handleReturn = () => {
        window.scrollTo(0, 0);
        navigate("/company");
    };

    const handleEditRegister = () => {
        setOpen(false);
        setCompleteOpen(true);
    };

    const [completeOpen, setCompleteOpen] = useState(false);

    const handleEditComplete = () => {
        setCompleteOpen(false);
        window.scrollTo(0, 0);
        navigate("/company");
    };

    return (
        <>
            <Helmet>
                <title>会社情報編集 - HR System (仮)</title>
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>

            <Box sx={{ pl: val === true ? 6 : 30 }}>
                <Container maxWidth="false" sx={{ mt: 10 }}>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mb: 5 }}>
                        <Typography variant="h6">会社情報を編集</Typography>
                    </Box>

                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mb: 1 }}>
                        <Typography sx={{ width: "600px", textAlign: "left" }}>基本情報</Typography>
                    </Box>
                    <Box>
                        <TextField
                            variant="outlined"
                            label={companyData.companyName.title}
                            value={companyData.companyName.value}
                            sx={{ width: "600px" }}
                            onChange={handleCompanyNameChange}
                            onBlur={handleCompanyNameBlur}
                            error={companyDataError.companyName !== ""}
                        />
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 1 }}>
                        {companyDataError.companyName !== "" && (
                            <Alert severity="error" sx={{ width: "600px" }}>
                                {companyDataError.companyName}
                            </Alert>
                        )}
                    </Box>

                    <Box sx={{ mt: 3 }}>
                        <TextField
                            variant="outlined"
                            label="代表の役職"
                            value={companyData.companyRepresentative.value1}
                            sx={{ width: "288px" }}
                            onChange={handleCompanyRepresentative1Change}
                            onBlur={handleCompanyRepresentative1Blur}
                            error={companyDataError.companyRepresentative1 !== ""}
                        />
                        <TextField
                            variant="outlined"
                            label="代表の氏名"
                            value={companyData.companyRepresentative.value2}
                            sx={{ width: "288px", ml: 3 }}
                            onChange={handleCompanyRepresentative2Change}
                            onBlur={handleCompanyRepresentative2Blur}
                            error={companyDataError.companyRepresentative2 !== ""}
                        />
                    </Box>
                    <Grid2 container sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 1 }}>
                        <Grid2 sx={{ width: "288px" }}>
                            {companyDataError.companyRepresentative1 !== "" && (
                                <Alert severity="error">{companyDataError.companyRepresentative1}</Alert>
                            )}
                        </Grid2>

                        <Grid2 sx={{ width: "288px", ml: 3 }}>
                            {companyDataError.companyRepresentative2 !== "" && (
                                <Alert severity="error">{companyDataError.companyRepresentative2}</Alert>
                            )}
                        </Grid2>
                    </Grid2>

                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mb: 1, mt: 3 }}>
                        <Typography sx={{ width: "600px", textAlign: "left" }}>住所</Typography>
                    </Box>
                    <Box>
                        <TextField
                            variant="outlined"
                            label="郵便番号"
                            value={companyData.companyAddress.post}
                            sx={{ width: "424px" }}
                            onChange={handleCompanyAddressPostChange}
                            onBlur={handleCompanyAddressPostBlur}
                            helperText="数字のみ入力してください"
                            error={companyDataError.companyAddressPost !== ""}
                            InputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                        />
                        <Button variant="outlined" size="large" sx={{ width: "152px", height: "56px", ml: 3 }}>
                            住所を検索する
                        </Button>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 1 }}>
                        {companyDataError.companyAddressPost !== "" && (
                            <Alert severity="error" sx={{ width: "600px" }}>
                                {companyDataError.companyAddressPost}
                            </Alert>
                        )}
                    </Box>

                    <Box sx={{ mt: 1 }}>
                        <TextField
                            variant="outlined"
                            label="都道府県"
                            value={companyData.companyAddress.prefecture}
                            sx={{ width: "288px" }}
                            onChange={handleCompanyAddressPrefectureChange}
                            onBlur={handleCompanyAddressPrefectureBlur}
                            error={companyDataError.companyAddressPrefecture !== ""}
                        />
                        <TextField
                            variant="outlined"
                            label="市区町村"
                            value={companyData.companyAddress.city}
                            sx={{ width: "288px", ml: 3 }}
                            onChange={handleCompanyAddressCityChange}
                            onBlur={handleCompanyAddressCityBlur}
                            error={companyDataError.companyAddressCity !== ""}
                        />
                    </Box>
                    <Grid2 container sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 1 }}>
                        <Grid2 sx={{ width: "288px" }}>
                            {companyDataError.companyAddressPrefecture !== "" && (
                                <Alert severity="error">{companyDataError.companyAddressPrefecture}</Alert>
                            )}
                        </Grid2>

                        <Grid2 sx={{ width: "288px", ml: 3 }}>
                            {companyDataError.companyAddressCity !== "" && (
                                <Alert severity="error">{companyDataError.companyAddressCity}</Alert>
                            )}
                        </Grid2>
                    </Grid2>

                    <Box sx={{ mt: 1 }}>
                        <TextField
                            variant="outlined"
                            label="上記以降の住所"
                            value={companyData.companyAddress.other}
                            sx={{ width: "600px" }}
                            onChange={handleCompanyAddressOtherChange}
                            onBlur={handleCompanyAddressOtherBlur}
                            error={companyDataError.companyAddressOther !== ""}
                        />
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 1 }}>
                        {companyDataError.companyAddressOther !== "" && (
                            <Alert severity="error" sx={{ width: "600px" }}>
                                {companyDataError.companyAddressOther}
                            </Alert>
                        )}
                    </Box>

                    <Box sx={{ mt: 1 }}>
                        <TextField
                            variant="outlined"
                            label="建物名・部屋番号"
                            value={companyData.companyAddress.building}
                            sx={{ width: "600px" }}
                            onChange={handleCompanyAddressBuildingChange}
                        />
                    </Box>

                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mb: 1, mt: 3 }}>
                        <Typography sx={{ width: "600px", textAlign: "left" }}>労働者派遣事業情報</Typography>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <FormControl sx={{ width: "100px" }}>
                            <Select
                                value={companyData.dispatchBusiness.value1}
                                onChange={handleDispatchBusiness1Change}
                            >
                                <MenuItem value="1">派</MenuItem>
                                <MenuItem value="2">地</MenuItem>
                            </Select>
                        </FormControl>
                        <TextField
                            variant="outlined"
                            value={companyData.dispatchBusiness.value2}
                            sx={{ width: "100px", ml: 3 }}
                            onChange={handleDispatchBusiness2Change}
                            onBlur={handleDispatchBusinessBlur}
                            error={companyDataError.dispatchBusiness2 !== ""}
                            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                        />
                        <Typography sx={{ mx: 1 }}>ー</Typography>
                        <TextField
                            variant="outlined"
                            value={companyData.dispatchBusiness.value3}
                            sx={{ width: "344px" }}
                            onChange={handleDispatchBusiness3Change}
                            onBlur={handleDispatchBusinessBlur}
                            error={companyDataError.dispatchBusiness3 !== ""}
                            InputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                        />
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 1 }}>
                        {companyDataError.dispatchBusiness !== "" && (
                            <Alert severity="error" sx={{ width: "600px" }}>
                                {companyDataError.dispatchBusiness}
                            </Alert>
                        )}
                    </Box>

                    <Box sx={{ mt: 3 }}>
                        <TextField
                            variant="outlined"
                            label={companyData.dispatchAmount.title}
                            value={companyData.dispatchAmount.value}
                            sx={{ width: "600px" }}
                            onChange={handleDispatchAmountChange}
                            onBlur={handleDispatchAmountBlur}
                            helperText="数字のみ入力してください"
                            error={companyDataError.dispatchAmount !== ""}
                            inputProps={{
                                inputMode: "numeric",
                                pattern: "[0-9]*",
                                style: { textAlign: "right" },
                            }}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">円</InputAdornment>,
                            }}
                        />
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 1 }}>
                        {companyDataError.dispatchAmount !== "" && (
                            <Alert severity="error" sx={{ width: "600px" }}>
                                {companyDataError.dispatchAmount}
                            </Alert>
                        )}
                    </Box>

                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mb: 1, mt: 3 }}>
                        <Typography sx={{ width: "600px", textAlign: "left" }}>労使協定</Typography>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Box sx={{ display: "flex", justifyContent: "left", alignItems: "center", width: "600px" }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={companyData.laborAgreement.value}
                                        onChange={handleLaborAgreementChange}
                                    />
                                }
                                label="締結済み"
                                sx={{ display: "flex", justifyContent: "left", alignItems: "center", height: "56px" }}
                            />
                            {companyData.laborAgreement.value === true && (
                                // DatePicker実装
                                <>
                                    <TextField
                                        variant="outlined"
                                        label="開始年月日"
                                        value={companyData.laborAgreement.startDate}
                                        onChange={handleLaborAgreementStartDateChange}
                                        onBlur={handleLaborAgreementBlur}
                                        error={companyDataError.laborAgreementStartDate !== ""}
                                        sx={{ width: "216px", ml: 3 }}
                                    />
                                    <Typography sx={{ mx: 1 }}>〜</Typography>
                                    <TextField
                                        variant="outlined"
                                        label="終了年月日"
                                        value={companyData.laborAgreement.endDate}
                                        onChange={handleLaborAgreementEndDateChange}
                                        onBlur={handleLaborAgreementBlur}
                                        error={companyDataError.laborAgreementEndDate !== ""}
                                        sx={{ width: "216px" }}
                                    />
                                </>
                            )}
                        </Box>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 1 }}>
                        {(companyDataError.laborAgreementStartDate !== "" ||
                            companyDataError.laborAgreementEndDate !== "") && (
                            <Alert severity="error" sx={{ width: "600px" }}>
                                {companyDataError.laborAgreement}
                            </Alert>
                        )}
                    </Box>

                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mb: 1, mt: 3 }}>
                        <Typography sx={{ width: "600px", textAlign: "left" }}>支払い条件</Typography>
                    </Box>
                    <Box>
                        <TextField
                            variant="outlined"
                            label={companyData.payment.closing}
                            value={companyData.payment.closingValue}
                            sx={{ width: "600px" }}
                            onChange={handlePaymentClosingChange}
                            onBlur={handlePaymentClosingBlur}
                            error={companyDataError.paymentClosing !== ""}
                        />
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 1 }}>
                        {companyDataError.paymentClosing !== "" && (
                            <Alert severity="error" sx={{ width: "600px" }}>
                                {companyDataError.paymentClosing}
                            </Alert>
                        )}
                    </Box>

                    <Box sx={{ mt: 1 }}>
                        <TextField
                            variant="outlined"
                            label={companyData.payment.date}
                            value={companyData.payment.dateValue}
                            sx={{ width: "600px" }}
                            onChange={handlePaymentDateChange}
                            onBlur={handlePaymentDateBlur}
                            error={companyDataError.paymentDate !== ""}
                        />
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 1 }}>
                        {companyDataError.paymentDate !== "" && (
                            <Alert severity="error" sx={{ width: "600px" }}>
                                {companyDataError.paymentDate}
                            </Alert>
                        )}
                    </Box>

                    <Box sx={{ mt: 1 }}>
                        <TextField
                            variant="outlined"
                            label={companyData.payment.method}
                            value={companyData.payment.methodValue}
                            sx={{ width: "600px" }}
                            onChange={handlePaymentMethodChange}
                            onBlur={handlePaymentMethodBlur}
                            error={companyDataError.paymentMethod !== ""}
                        />
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 1 }}>
                        {companyDataError.paymentMethod !== "" && (
                            <Alert severity="error" sx={{ width: "600px" }}>
                                {companyDataError.paymentMethod}
                            </Alert>
                        )}
                    </Box>

                    <Box sx={{ mt: 5 }}>
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            onClick={handleEditConfirm}
                            sx={{ width: "400px" }}
                        >
                            編集内容を確認する
                        </Button>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 1 }}>
                        {validation !== "" && (
                            <Alert severity="error" sx={{ width: "400px" }}>
                                {validation}
                            </Alert>
                        )}
                    </Box>

                    <Box sx={{ mt: 5, mb: 10 }}>
                        <Button
                            variant="outlined"
                            color="primary"
                            size="large"
                            onClick={handleReturn}
                            sx={{ width: "400px" }}
                        >
                            会社情報へ戻る
                        </Button>
                    </Box>
                </Container>
            </Box>

            <Dialog maxWidth="md" fullWidth open={open} onClose={handleClose}>
                <DialogTitle sx={{ mb: 2 }}>
                    <IconButton sx={{ position: "absolute", right: 8, top: 8 }} onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Typography variant="h6" sx={{ textAlign: "center", mb: 5 }}>
                        会社情報の編集内容を確認
                    </Typography>

                    {Object.entries(companyData).map(([key, data]) => (
                        <Box key={key}>
                            <Grid2 container spacing={2}>
                                <Grid2
                                    size={{ xs: 4, md: 4 }}
                                    sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}
                                >
                                    <Typography fontWeight="bold">{data.title}</Typography>
                                </Grid2>
                                <Grid2
                                    size={{ xs: 8, md: 8 }}
                                    sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}
                                >
                                    {key === "companyRepresentative" ? (
                                        <>
                                            <Typography>{data.value1}</Typography>
                                            <Typography sx={{ ml: 2 }}>{data.value2}</Typography>
                                        </>
                                    ) : key === "companyAddress" ? (
                                        <>
                                            <Typography sx={{ textAlign: "left" }}>
                                                {"〒" + data.post.replace(/(\d{3})(?=\d)/, "$1-")}
                                                <br />
                                                {data.prefecture}
                                                {data.city}
                                                {data.other} {data.building}
                                            </Typography>
                                        </>
                                    ) : key === "dispatchBusiness" ? (
                                        <>
                                            <Typography>
                                                {data.value1 === 1 ? "派" : data.value1 === 2 ? "地" : data.value1}
                                                {data.value2}-{data.value3}
                                            </Typography>
                                        </>
                                    ) : key === "dispatchAmount" ? (
                                        <Typography>
                                            {data.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}円
                                        </Typography>
                                    ) : key === "laborAgreement" ? (
                                        <Typography sx={{ textAlign: "left" }}>
                                            {data.value ? "締結済み" : "なし"}
                                            {data.value === true && (
                                                <>
                                                    <br />
                                                    {data.startDate} 〜 {data.endDate}
                                                </>
                                            )}
                                        </Typography>
                                    ) : key === "payment" ? (
                                        <Typography sx={{ textAlign: "left" }}>
                                            {data.closing}　　　{data.closingValue}
                                            <br />
                                            {data.date}　　　{data.dateValue}
                                            <br />
                                            {data.method}　　{data.methodValue}
                                        </Typography>
                                    ) : (
                                        <Typography>{data.value}</Typography>
                                    )}
                                </Grid2>
                            </Grid2>
                            <Divider sx={{ my: 1 }} />
                        </Box>
                    ))}

                    <Box sx={{ mt: 5, display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            onClick={handleEditRegister}
                            sx={{ width: "400px" }}
                        >
                            編集内容を登録する
                        </Button>
                    </Box>

                    <Box sx={{ mt: 5, mb: 5, display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Button
                            variant="outlined"
                            color="primary"
                            size="large"
                            onClick={handleClose}
                            sx={{ width: "400px" }}
                        >
                            編集画面へ戻る
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>

            <Dialog open={completeOpen}>
                <DialogContent sx={{ mt: 2 }}>
                    <DialogContentText style={{ textAlign: "center", color: "#000" }}>
                        会社情報の編集が完了しました。
                    </DialogContentText>
                    <Button variant="contained" size="large" sx={{ width: 400, mt: 5 }} onClick={handleEditComplete}>
                        OK
                    </Button>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default CompanyEdit;
