import React, { useMemo } from "react";
import { Container, Typography, Box, Button, Grid2, Tooltip, IconButton } from "@mui/material";

import { MaterialReactTable } from "material-react-table";
import { MRT_Localization_JA } from "material-react-table/locales/ja";

import { useNavigate } from "react-router-dom";

import SettingsIcon from "@mui/icons-material/Settings";

import { Helmet } from "react-helmet-async";

const Staff = ({ val }) => {
    const navigate = useNavigate();

    const staffData = [
        {
            name: "桐ヶ谷 和人",
            gender: "男性",
            age: 18,
            employment:
                "株式会社めっちゃ名前長い企業がちゃんと三点リーダーで表示されるかテストしています。マウスオーバーすると、すべて表示されます。",
            enrollment: "就業中",
        },
        { name: "結城 明日奈", gender: "女性", age: 18, employment: "企業A", enrollment: "就業中" },
        { name: "朝田 詩乃", gender: "女性", age: 17, employment: "企業B", enrollment: "就業中" },
        { name: "桐ヶ谷 直葉", gender: "女性", age: 16, employment: "企業C", enrollment: "離職中" },
        { name: "ユイ", gender: "女性", age: 10, employment: "企業D", enrollment: "就業中" },
        { name: "リーファ", gender: "女性", age: 16, employment: "企業E", enrollment: "退職済" },
        { name: "シリカ", gender: "女性", age: 14, employment: "企業F", enrollment: "就業中" },
        { name: "リズベット", gender: "女性", age: 18, employment: "企業G", enrollment: "就業中" },
        { name: "クライン", gender: "男性", age: 24, employment: "企業H", enrollment: "就業中" },
        { name: "エギル", gender: "男性", age: 27, employment: "企業I", enrollment: "就業中" },
        { name: "シノン", gender: "女性", age: 17, employment: "企業J", enrollment: "就業中" },
        { name: "ユウキ", gender: "女性", age: 19, employment: "企業K", enrollment: "就業中" },
        { name: "アリス", gender: "女性", age: 20, employment: "企業L", enrollment: "就業中" },
        { name: "ユージオ", gender: "男性", age: 20, employment: "企業M", enrollment: "就業中" },
        { name: "ティーゼ", gender: "女性", age: 16, employment: "企業N", enrollment: "就業中" },
        { name: "ロニエ", gender: "女性", age: 16, employment: "企業O", enrollment: "就業中" },
        { name: "セルカ", gender: "女性", age: 12, employment: "企業P", enrollment: "就業中" },
        { name: "カーディナル", gender: "女性", age: 200, employment: "企業Q", enrollment: "就業中" },
        { name: "アスナ", gender: "女性", age: 18, employment: "企業R", enrollment: "退職済" },
        { name: "キリト", gender: "男性", age: 18, employment: "企業S", enrollment: "退職済" },
    ];

    const columns = useMemo(
        () => [
            {
                header: "氏名",
                accessorKey: "name",
                Cell: ({ cell }) => (
                    <Tooltip title={cell.getValue()} placement="top" arrow>
                        <Typography
                            variant="body2"
                            sx={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                            }}
                        >
                            {cell.getValue()}
                        </Typography>
                    </Tooltip>
                ),
                muiTableBodyCellProps: { sx: { maxWidth: "500px" } },
            },
            {
                header: "性別",
                accessorKey: "gender",
                muiTableBodyCellProps: { sx: { maxWidth: "100px" } },
            },
            {
                header: "年齢",
                accessorKey: "age",
                muiTableBodyCellProps: { sx: { maxWidth: "100px" } },
                Cell: ({ cell }) => cell.getValue().toLocaleString(),
            },
            {
                header: "就業先企業",
                accessorKey: "employment",
                Cell: ({ cell }) => (
                    <Tooltip title={cell.getValue()} placement="top" arrow>
                        <Typography
                            variant="body2"
                            sx={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                            }}
                        >
                            {cell.getValue()}
                        </Typography>
                    </Tooltip>
                ),
                muiTableBodyCellProps: { sx: { maxWidth: "500px" } },
            },
            {
                header: "在籍区分",
                accessorKey: "enrollment",
                muiTableBodyCellProps: { sx: { maxWidth: "100px" } },
            },
        ],
        []
    );

    return (
        <>
            <Helmet>
                <title>スタッフ一覧 - HR System (仮)</title>
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>

            <Box sx={{ pl: val === true ? 6 : 30 }}>
                <Container maxWidth="false" sx={{ mt: 10 }}>
                    <Grid2 container>
                        <Grid2
                            size={{ xs: 8, md: 8 }}
                            sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}
                        >
                            <Typography variant="h6">スタッフ一覧</Typography>
                        </Grid2>
                        <Grid2
                            size={{ xs: 4, md: 4 }}
                            sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}
                        >
                            <Button variant="outlined" size="large" color="primary">
                                スタッフを追加する
                            </Button>
                            <IconButton sx={{ ml: 2 }}>
                                <SettingsIcon />
                            </IconButton>
                        </Grid2>
                    </Grid2>

                    <Box sx={{ mt: 2 }}>
                        <MaterialReactTable
                            columns={columns}
                            data={staffData}
                            columnFilterDisplayMode="popover"
                            enableColumnActions={false}
                            enableColumnResizing={false}
                            enableDensityToggle={false}
                            enableFullScreenToggle={false}
                            enableHiding={false}
                            localization={MRT_Localization_JA}
                            initialState={{ pagination: { pageIndex: 0, pageSize: 10 }, showGlobalFilter: true }}
                            muiPaginationProps={{ shape: "rounded", color: "primary" }}
                            paginationDisplayMode="pages"
                            positionGlobalFilter="left"
                        />
                    </Box>
                </Container>
            </Box>
        </>
    );
};

export default Staff;
